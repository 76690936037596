$baseUrl: "/";
@import '_config';

.checkboxContainer {
    margin: 0;
}

.regenerateIcon {
    color: $color-gray;
    transition: rotate 400ms;

    svg {
        width: 100%;
        height: 100%;
    }
}

.regenerateContainer {
    display: inline-flex;
    align-items: center;
}

.backtoDefaultIcon {
    color: $color-gray;
    width: 17px;
    height: 17px;

    margin-left: $grid;
    transition: rotate 400ms;

    &:hover {
        cursor: pointer;
        color: #414141;
        rotate: -90deg;
    }
}

.regenerateIcon:hover,
.checkboxIcon:hover {
    color: #414141;
    cursor: pointer;
    rotate: -90deg;
}
